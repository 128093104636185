<template>
	<div class="container">
		<div class="search_box">
			<a-form-model ref="shopDownPaymentForms" :model="shopDownPaymentForm" :labelCol="{span: 6}" :wrapperCol="{span: 18}" :labelAlign="'right'">
				<a-row :gutter="20">
					<a-col :span="6">
						<a-form-model-item prop="receipt_number" label="收据单号" :colon="false">
							<a-input allowClear v-model="shopDownPaymentForm.receipt_number" placeholder="请输入收据单号" />
						</a-form-model-item>
					</a-col>
					<a-col :span="6">
						<a-form-model-item prop="name" label="预订人" :colon="false">
							<a-input allowClear v-model="shopDownPaymentForm.name" placeholder="请输入预订人" />
						</a-form-model-item>
					</a-col>
					<a-col :span="6">
						<a-form-model-item prop="phone" label="预订手机号" :colon="false">
							<a-input allowClear v-model="shopDownPaymentForm.phone" placeholder="请输入预订手机号" />
						</a-form-model-item>
					</a-col>
					<a-col :span="6">
						<a-form-model-item prop="pay_status" label="支付状态" :colon="false">
							<a-select placeholder="请选择支付状态" allowClear v-model="shopDownPaymentForm.pay_status" :options="payStatusData" />
						</a-form-model-item>
					</a-col>
					<a-col :span="6">
						<a-form-model-item prop="check_status" label="核销状态" :colon="false">
							<a-select placeholder="请选择核销状态" allowClear v-model="shopDownPaymentForm.check_status" :options="checkStatusData" />
						</a-form-model-item>
					</a-col>
					<a-col :span="6">
						<a-form-model-item prop="order_date" label="预订日期" :colon="false">
							<a-range-picker valueFormat="YYYY-MM-DD" allowClear v-model="shopDownPaymentForm.order_date" />
						</a-form-model-item>
					</a-col>
					<a-col :span="6">
						<a-form-model-item prop="pay_date" label="支付时间" :colon="false">
							<a-range-picker valueFormat="YYYY-MM-DD" allowClear v-model="shopDownPaymentForm.pay_date" />
						</a-form-model-item>
					</a-col>
					<a-col :span="6">
						<a-form-model-item prop="check_date" label="核销时间" :colon="false">
							<a-range-picker valueFormat="YYYY-MM-DD" allowClear v-model="shopDownPaymentForm.check_date" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<div class="operation-space">
							<a-space>
								<a-button type="primary" icon="search" @click="handleSubmit">搜索</a-button>
								<a-button icon="redo" @click="handleReset">重置</a-button>
								<a-button type="primary" @click="exportData" icon="download">导出</a-button>
							</a-space>
						</div>
					</a-col>
				</a-row>
			</a-form-model>
		</div>
		<a-table :expandedRowKeys="expandedRowKeys" :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" :columns="columns" :data-source="list" :pagination="pagination" @change="handleTableChange" rowKey="id"  @expand="expandedRowsChange">
			<div slot="expandedRowRender" class="descriptions_box" slot-scope="record">
				<a-descriptions title="预订信息" size="small">
					<a-descriptions-item label="预订方式">
						{{ record.orderName }}
					</a-descriptions-item>
					<a-descriptions-item label="支付状态">{{(record.pay_status && record.pay_status === 2)?'已退款':'已支付'}}</a-descriptions-item>
					<a-descriptions-item label="核销状态">
						{{ record.with_check && record.with_check.id ? '已核销':'未核销'}}
					</a-descriptions-item>
					<a-descriptions-item label="应收金额"> {{ record.pay_money }}元 </a-descriptions-item>
					<a-descriptions-item label="实收金额"> {{ record.pay_money }}元 </a-descriptions-item>
					<a-descriptions-item label="餐位定金"> {{ record.pay_money }}元 </a-descriptions-item>
					<a-descriptions-item label="菜品服务"> 0元 </a-descriptions-item>
					<a-descriptions-item label="定金抵扣"> 否 </a-descriptions-item>
					<a-descriptions-item label="销售">
						{{ record?.with_room_order?.with_mini_bind_user?.nickname ?? '前台' }}
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions title="支付信息" size="small">
					<a-descriptions-item label="支付人">
						{{ record.with_room_order.name }}
					</a-descriptions-item>
					<a-descriptions-item label="支付方式">
						{{ record.reservationTypeText }}
					</a-descriptions-item>
					<a-descriptions-item label="支付时间">
						{{ record.pay_date }}
					</a-descriptions-item>
					<a-descriptions-item label="支付凭证">
						<a-space v-if="record.pay_image.length">
							<img
								alt="example"
								v-for="(item, index) in record.pay_image"
								:key="index"
								style="width: 80px; height: 80px"
								:src="item"
								@click="
									{
										previewImage = item
										previewVisible = true
									}
								" />
						</a-space>
						<template v-else>暂无</template>
					</a-descriptions-item>
					<a-descriptions-item label="核销人">
						{{ record.with_check && record.with_check.id ? record.with_check?.with_sale?.nickname ?? '前台' : '无' }}
					</a-descriptions-item>
					<a-descriptions-item label="核销方式">
						{{ record?.with_check?.with_checkName ?? '无' }}
					</a-descriptions-item>
					<a-descriptions-item label="核销时间">
						{{ record.with_check && record.with_check.id ? record.with_check?.created_atText : '无' }}
					</a-descriptions-item>
				</a-descriptions>
				<a-descriptions title="收据信息" size="small" >
					<a-descriptions-item label="收据单号" :span="1">
						{{ record.receipt_number }}
					</a-descriptions-item>
					<a-descriptions-item label="核销码" :span="2">
						<canvas ref="barcodeCanvas" style="display: none;"></canvas>
						<img class="barcodeCanvas_img" :src="record.base64ImageData" alt="">
					</a-descriptions-item>
					<a-descriptions-item label="打印状态">
						{{ record.print_status === 0?'未打印':'已打印' }}
					</a-descriptions-item>
				</a-descriptions>
			</div>
			<template slot="operation" slot-scope="text, record">
				<a-space>
					<a-popconfirm v-if="(!record.with_check || !record.with_check.id) && record.pay_status && record.pay_status === 1" title="确定要将该笔定金操作退款吗?" ok-text="确定" cancel-text="取消" @confirm="refundDeposit(record)">
						<a-button class="action-button" type="primary" size="small">退款</a-button>
					</a-popconfirm>
					<a-popconfirm v-if="(!record.with_check || !record.with_check.id) && record.pay_status && record.pay_status === 1" title="确定核销当前定金?" ok-text="核销" cancel-text="取消" @confirm="setWriteOffItem(record)">
						<a-button class="action-button" type="primary" size="small">核销</a-button>
					</a-popconfirm>
					<a-button class="action-button" size="small" @click="checkInvoiceInfo(record)">查看收据</a-button>
				</a-space>
			</template>
		</a-table>
		<a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
			<img alt="example" style="width: 100%; margin-top: 20px" :src="previewImage" />
		</a-modal>
		<a-modal title="查看收据" :visible="invoiceInfoVisible" width="1130px"  okText="打印" @ok="handleOk" @cancel="invoiceInfoCancel">
			<invoice ref="invoice" :invoiceInfo="invoiceInfo" />
		</a-modal>
		<iframe ref="printIframe" style="display: none;"></iframe>
	</div>
</template>

<script>
import Invoice from './invoice.vue'
import moment from 'moment'
import JsBarcode from 'jsbarcode'
import {getDepositList, setWriteOff,setPrint,refundDeposit} from '@/api/moneyManage'
import {getOrderType} from '@/utils/utils'
import {mapState} from 'vuex'
import {getConfig} from '@/api/personalization'
const reservationType = [
	{label: '现金', value: 1},
	{label: '刷卡', value: 2},
	{label: '微信', value: 3},
	{label: '支付宝', value: 4},
	{label: '转账', value: 5},
	{label: '银行', value: 6},
	{label: '其它', value: 7}
]
const orderTypeData = {
	1: '预订本'
}
const withCheckType = {
	0: '小程序',
	1: '客户端'
}
export default {
	name: 'shopDownPayment',
	components: {Invoice},
	data() {
		return {
			expandedRowKeys:[],//默认展开行
			payStatusData: [
				{label: '未支付', value: 0},
				{label: '已支付', value: 1},
				{label: '已退款', value: 2},
			],
			checkStatusData: [
				{label: '未核销', value: 0},
				{label: '已核销', value: 1}
			],
			invoiceInfo: {
				name: '',
				notes: '',
				invoiceTime: moment().format('YYYY年MM月DD日'),
				diningDate: moment().format('YYYY年MM月DD日'),
				diningTime: moment().format('HH点mm分'),
				invoiceNo: 'XXXXXXX',
				orderType: '午餐',
				price: 100,
				remarks: '无',
				accountant: '',
				with_roomTitle: '预订餐位',
				rerson: '预订客户',
				salesName: '销售',
				paymentType: '微信',
				barcodeNumber: ''
			},
			invoiceInfoVisible: false,
			selectedRowKeys: [],
			list: [],
			columns: [
				{
					title: '定金编号',
					align: 'center',
					dataIndex: 'receipt_number'
				},
				{
					title: '预订人',
					align: 'center',
					dataIndex: 'with_room_order.name'
				},
				{
					title: '预订人手机号',
					align: 'center',
					dataIndex: 'with_room_order.phone'
				},
				{
					title: '预订餐位',
					align: 'center',
					dataIndex: 'with_room_order.with_room.title'
				},
				{
					title: '预订日期',
					align: 'center',
					dataIndex: 'with_room_order.order_date'
				},
				{
					title: '餐段',
					align: 'center',
					dataIndex: 'with_room_order.orderTypeText'
				},
				{
					title: '操作',
					align: 'center',
					dataIndex: 'operation',
					scopedSlots: {customRender: 'operation'}
				}
			],
			pagination: {
				current: 1,
				defaultPageSize: 10,
				showTotal: total => `共${total}条`
			},
			previewImage: '',
			previewVisible: false,
			shopDownPaymentForm: {
				receipt_number: '', //收据单号
				name: '', //预定人姓名
				phone: '', //预定人手机号
				// pay_money: '', //支付金额
				pay_status: undefined, //支付状态
				check_status: undefined, //核销状态
				order_date: [ ], //下单时间
				pay_date: [ ], //支付时间
				check_date: [ ] //核销时间
			},
			page: 1
		}
	},
	async mounted() {
		// 监听扫码枪操作
		document.addEventListener('keydown', this.handleKeyDown);
		await this.fetchSetting()
		this.initData()
	},
	beforeDestroy() {
		document.removeEventListener('keydown', this.handleKeyDown);
		clearTimeout(this.scanTimeout);
  	},
	computed: {
		...mapState('user', {userInfo: 'info'})
	},
	methods: {
		// 监听扫码枪操作
		handleKeyDown(event) {
			const key = event.key;
			if((this.scanBuffer && this.scanBuffer.indexOf('Shift')>=0) || key === 'Shift'){
				this.scanBuffer += key;
				clearTimeout(this.scanTimeout);
				if(document.body === event.target){
					this.scanTimeout = setTimeout(() => {
						if (this.scanBuffer.length) {
							// 只保留数字
							const numbersOnly = this.scanBuffer.match(/\d+/g);
							if (numbersOnly) {
								if(numbersOnly.join('').length === 22){
									this.shopDownPaymentForm.receipt_number = numbersOnly.join('');
									this.handleSubmit()
								}else{
									this.scanBuffer = ''
								}
							}
						}
					}, 300);
				}
			}
		},
		/**
		 * @description: 获取配置信息
		 * @return {*}
		 */
		async fetchSetting() {
			const {code, data = {}} = await getConfig()
			if (code === 0) {
				this.invoiceInfo = {
					...this.invoiceInfo,
					name: data?.depositTemplate?.name || '订餐定金专用收据（凭单消费）',
					notes: data?.depositTemplate?.notes || '此定金专用收据仅限当餐抵用、遗失不补、违约不退、过期作废',
					accountant: data?.depositTemplate?.accountant || '',
				}
			}
		},
		// 搜索
		handleSubmit(e, param = {},isReset=true) {
			let parameters = JSON.parse(JSON.stringify(this.shopDownPaymentForm))
			for (var key in parameters) {
				if (parameters[key] === '' || (typeof parameters[key] ==='object' && !parameters[key].length)) {
					delete parameters[key]
				}
			}
			this.initData({...parameters, ...param},isReset)
		},
		// 重置
		handleReset() {
			this.$refs.shopDownPaymentForms.resetFields()
			this.initData()
		},
		// 导出
		exportData(e) {
			let param = {
				export: 1
			}
			if (this.selectedRowKeys.length) {
				param.ids = this.selectedRowKeys
			}
			this.handleSubmit(e, param)
		},
		// 初始化
		initData(parameters = {},isReset=true) {
			this.expandedRowKeys = [];
			if (!parameters.export && isReset) {
				this.list = []
				this.page = 1
			}
			this.getDepositList(parameters)
		},
		//查询列表
		async getDepositList(parameters={}) {
			const currentFocus = document.activeElement;
			if (currentFocus) { //元素失去焦点
				currentFocus.blur();
			}
			this.$store.dispatch('loading/startContentLoading')
			const {code, data} = await getDepositList({
				page: this.page,
				pageSize: this.pagination.defaultPageSize,
				...parameters
			})
			let meal_segment = this.userInfo?.shop?.to_sms_config?.sms_config?.meal_segment ?? []
			if (code === 0) {
				if (parameters.export) {
					//导出
					const encodedUri = encodeURI(data.url)
					const link = document.createElement('a')
					link.setAttribute('href', encodedUri)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} else {
					this.list = data.data.map(item => {
						item.with_room_order.orderTypeText = getOrderType(meal_segment, item.with_room_order.order_type, item.with_room_order.order_round)
						item.reservationTypeText = reservationType.find(items => items.value === item.pay_type)?.label
						if (item.with_check && item.with_check.id) {
							item.with_check.created_atText = item.with_check?.created_at ? moment(item.with_check.created_at).format('YYYY年MM月DD日 HH点mm分') : ''
							item.with_check.with_checkName = withCheckType[item.with_check.type] ?? '无'
						}
						item.orderName = orderTypeData[item.order_type] ?? '无';
						item.base64ImageData = ''
						return item
					})
					this.pagination = {
						current: data.current_page,
						defaultPageSize: 10,
						total: data.total
					}
					if(this.scanBuffer && this.list.length){//扫码枪默认打开第一个
						this.expandedRowsChange(true,this.list[0])
					}
					this.scanBuffer= '';
				}
			}
			this.$store.dispatch('loading/endContentLoading')
		},
		// 分页
		handleTableChange(page) {
			let parameters = JSON.parse(JSON.stringify(this.shopDownPaymentForm))
			for (var key in parameters) {
				if (parameters[key] === '' || (typeof parameters[key] ==='object' && !parameters[key].length)) {
					delete parameters[key]
				}
			}
			this.expandedRowKeys = [];
			this.page = page.current
			this.getDepositList(parameters)
		},
		//生成条形码
		expandedRowsChange(expanded, record) {
			let index = this.expandedRowKeys.findIndex(item=> item === record.id);
			if(expanded){
				this.expandedRowKeys.push(record.id)
			}else{
				this.expandedRowKeys.splice(index, 1);
			}
			this.$nextTick(() => {
				const canvas = this.$refs.barcodeCanvas
				JsBarcode(canvas, `DP${record.receipt_number}`, {
					height: 50,
					width: 2,
					format: 'CODE128', // 条形码格式，可根据需要调整，常见的有CODE128、EAN13等
					lineColor: '#000'
				});
				let index = this.list.findIndex(item=> item.id === record.id);
				this.list[index].base64ImageData = canvas.toDataURL('image/png');
			})
		},
		// 选中
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		},
		//查看收据
		checkInvoiceInfo(record) {
			const currentFocus = document.activeElement;
			if (currentFocus) { //元素失去焦点
				currentFocus.blur();
			}
			let with_roomTitle = record?.with_room_order?.with_room?.with_category?.title ?? ''
			if (with_roomTitle) {
				with_roomTitle = with_roomTitle + '-' + record.with_room_order.with_room.title
			} else {
				with_roomTitle = record.with_room_order.with_room.title
			}
			this.invoiceInfo = {
				...this.invoiceInfo,
				invoiceTime: moment(record.updated_at).format('YYYY年MM月DD日'),
				diningDate: moment(record.with_room_order.order_date).format('YYYY年MM月DD日'),
				diningTime: record.with_room_order.order_time != '待定' ? moment(record.with_room_order.order_date + ' ' + record.with_room_order.order_time).format('HH点mm分') : record.with_room_order.order_time,
				invoiceNo: record.receipt_number,
				orderType: record.with_room_order.orderTypeText,
				price: record.pay_money,
				remarks: '',
				with_roomTitle,
				rerson: record.with_room_order.name,
				salesName: record?.with_room_order?.with_mini_bind_user?.nickname ?? '前台',
				paymentType: record.reservationTypeText,
				barcodeNumber: `DP${record.receipt_number}`,
				print_status:record.print_status || 0,
			}
			this.invoiceInfoVisible = true
		},
		// 打印
		async handleOk() {
			const iframe = this.$refs.printIframe;
			const invoiceComponent = this.$refs.invoice; // 尝试通过ref获取Invoice组件实例
			if (invoiceComponent) {
				const componentHTML = invoiceComponent.$el.outerHTML;
				iframe.contentDocument.open();
				iframe.contentDocument.write(`
					<html>
						<head>
						<title>发票打印</title>
						<style lang="less">
							@media print {
								@page {
									// margin: auto 0;
									size:auto;
									margin-left: 0;
									margin-right: 0;
									margin-buttom: 0;
								}
								.invoice_box{
									width: 100%;
								}
							}
							body, html{
								margin: 0;
								padding:0;
							}
							.invoice_box{
								position: relative;
								color: #333333;
								font-weight: 500;
								width: 100%;
								.table_tr,.table_td{
									display: flex;
									align-items: center;
								}
								.table_tr{
									width: 100%;
									flex: 1;
									padding: 10px 0;
								}
								.table_title{
									min-width: 110px;
									text-align: left;
								}
								.padding110{
									padding-left: 110px;
									box-sizing: border-box;
								}
							}
							.invoice_title{
								font-size: 22pt;
								font-weight: bold;
								text-align: center;
							}
							.invoice_info{
								display: flex;
								align-items: center;
								justify-content: space-between;
								.invoice_info_text{
									padding: 10px;
									flex: 1;
									display: flex;
									align-items: center;
								}
							}
							.invoice_tips{
								font-size: 18pt;
								text-align: center;
								font-weight: bold;
							}
							.table_box{
								border: 1px solid #333333;
								padding: 10px;
								display: flex;
								flex-direction: column;
							}
							.invoice_input{
								border:none;
								border-radius: 0%;
								border-bottom: 1px solid #333333;
								border-bottom-style: dashed;
							}
							.table_td{
								border-bottom: 1px solid #333333;
								border-bottom-style: dashed;
								flex: 1;
								.invoice_input{
									border:none;
									border-radius: 0%;
								}
							}
							.wid40{
								width: 40%;
							}
							.wid50{
								width: 50%;
							}
							.wid30{
								width: 30%;
							}
							.flex1{
								flex: 1;
								flex-shrink: 0;
							}
							.barcodeCanvas_img{
								height: 70px;
							}
						</style>
						</head>
						<body>
							<div>
								<div style="height: 50%">
									<div style="padding: 0 22pt;">
									${componentHTML}	
									</div>
								</div>
							</div>
						</body>
					</html>
				`);
				iframe.contentDocument.close();
				iframe.contentWindow.focus();
				iframe.contentWindow.print();
				await setPrint({receipt_number:this.invoiceInfo.invoiceNo})
			}
		},
		//退款
		async refundDeposit(record){
			let {code} = await refundDeposit({
				receipt_number: record.receipt_number
			})
			if (code === 0) {
				this.$message.success('退款成功')
			}
			this.handleSubmit({},{},false)
		},
		//核销
		async setWriteOffItem(record) {
			let {code} = await setWriteOff({
				receipt_number: record.receipt_number
			})
			if (code === 0) {
				this.$message.success('核销成功')
			}
			this.handleSubmit({},{},false)
		},
		//关闭弹框
		invoiceInfoCancel(){
			this.invoiceInfoVisible = false
			this.handleSubmit({},{},false)
		}
	}
}
</script>
<style lang="less">
@media print {
	/* 隐藏不需要打印的元素，例如某个导航栏 */
	.ant-modal-header {
		display: none;
	}
	.ant-modal-close {
		display: none;
	}
	.ant-modal-footer {
		display: none;
	}
	.ant-modal-content {
		box-shadow: none;
	}
	.ant-modal {
		top: 0;
		margin: 0;
		width: 100%;
	}
	#app {
		display: none;
	}
}
</style>
<style lang="less" scoped>
.container {
	padding: 25px 20px 0 20px;
	max-width: 100%;
	border-radius: 4px;
	box-sizing: border-box;
	.search_box {
		margin-bottom: 20px;
	}
	.operation-space {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		width: 100%;
	}
	.barcodeCanvas_img{
		height: 92x;
		width: 398px;
	}
}
</style>
